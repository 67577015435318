/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onInitialClientRender = () => {
    if ('onGatsbyInitialClientRender' in window && typeof window.onGatsbyInitialClientRender === 'function') {
        window.onGatsbyInitialClientRender();
    }
};

// exports.onRouteUpdate = () => {
//     if ('onGatsbyRouteUpdate' in window && typeof window.onGatsbyRouteUpdate === 'function') {
//     	window.XMATA_HTW.init({domain:'https://app.xmata.io'}, function() {
//     		if(!document.getElementById('xm-ht-widget') ){
//     			window.XMATA_HTW.configure({ id: 'xm_hashtag_widget', domain: 'https://app.xmata.io', width:'1000', poweredBy:false});
//     		}
//             });
//         window.onGatsbyRouteUpdate();
//     }
// };

exports.onPreRouteUpdate = () => {
    if ('onGatsbyPreRouteUpdate' in window && typeof window.onGatsbyPreRouteUpdate === 'function') {
        window.onGatsbyPreRouteUpdate();
    }
};
